import {Helmet} from 'react-helmet-async';
import React from 'react';

import {PageType} from 'web/helpers/redux_client';
import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import {MarketLayoutStore} from 'web/components/market_layout/store_builder';

import TermsLayout from '../components/terms_layout';

const MembershipPage: PageType<MarketLayoutStore> = () => {
  const terms = (
    <main>
      <h3>Good Eggs Membership Program Terms &amp; Conditions</h3>
      <p>
        The terms and conditions set forth below ({'"'}Terms and Conditions{'"'}) apply to the Good
        Eggs Membership Program. These Terms and Conditions are between you ({'"'}Member{'"'} or
        {'"'}you{'"'}) and Good Eggs ({'"'}Good Eggs{'"'}, {'"'}we{'"'} or {'"'}us{'"'}). By
        registering for, joining or using the Membership Program, you hereby consent to enter into
        an electronic agreement and for Good Eggs to send to you electronic communications about
        your Membership Program ({'"'}Membership{'"'}), as further set forth below, and you hereby
        agree to these Terms and Conditions.
      </p>

      <h4>Membership Cancellation</h4>
      <p>
        You may pause or cancel your Good Eggs membership at any time by visiting Your Account page.
        If you pause, your benefits and billing will resume at the end of the pause period. If you
        cancel, your benefits will remain active through the current billing period, and your
        membership will not renew for the next renewal period (month or year, depending on the
        membership type).
      </p>

      <h4>Benefits</h4>
      <p>
        Membership benefits apply to orders placed on the goodeggs.com marketplace, as outlined in
        the{' '}
        <a className="membership-terms__link" href="/join-membership">
          Join Membership
        </a>{' '}
        page.
      </p>

      <h4>Other Limitations</h4>
      <ul data-testid="other-limitations-list">
        <li>Good Eggs may send you emails, texts, or call related to your membership.</li>
        <li>You may not transfer your membership.</li>
        <li>Good Eggs may choose in its sole discretion to add or remove membership benefits.</li>
        <li>Good Eggs reserves the right to refuse membership at our discretion.</li>
      </ul>

      <h4>Fees and Renewal</h4>
      <p>
        The membership fee is stated in the{' '}
        <a className="membership-terms__link" href="/join-membership">
          Join Membership
        </a>{' '}
        page. The membership fee is non-refundable. Taxes may apply on the membership fee. If the
        payment method on file is declined for payment of your membership fee, you must provide us a
        new eligible payment method promptly, or your membership will be canceled.
      </p>

      <h4>Promotional Trial Memberships</h4>
      <p>
        We may offer certain customers trial memberships, which are subject to these terms. Trial
        members may cancel before the end of the trial period to avoid being charged.
      </p>

      <h4>Agreement Changes</h4>
      <p>
        We reserve the right to change these terms, or any aspect of your Good Eggs membership, at
        our discretion and without notice to you.
      </p>

      <h4>Termination by Us</h4>
      <p>
        We may terminate your Good Eggs membership at our discretion without notice. We will not
        give a refund for termination related to conduct that we determine, in our discretion,
        violates these Terms or any applicable law.
      </p>

      <h4>Limitation of Liability</h4>
      <p>Our total liability will not exceed the last membership fee you paid.</p>
    </main>
  );

  return (
    <MarketLayout disableUpcomingOrdersBanner>
      <Helmet>
        <title>Membership Terms | Good Eggs</title>
      </Helmet>
      <TermsLayout currentSlug="membership">{terms}</TermsLayout>
    </MarketLayout>
  );
};

MembershipPage.pageName = 'Good Eggs Membership Terms';
MembershipPage.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  return marketLayoutReducer(state, action);
};

export default MembershipPage;
